import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";

const ENV_PREPEND: string = "REACT_APP_FEATURE_";

export enum Feature {
  OrdersCloseConfirm = "ORDERS_CLOSE_CONFIRM",
  OrdersReferrerDetails = "ORDERS_REFERRER_DETAILS",
  OrdersFittingQuality = "ORDERS_FITTING_QUALITY",
  TextToSentence = "TEXT_TO_SENTENCE",
  OrdersTips = "ORDERS_TIPS",
  OrdersCleanRationale = "ORDERS_CLEAN_RATIONALE",
  OrdersVlotMatching = "ORDERS_VLOT_MATCHING",
}

const featFlagFromEnv = (
  feature: Feature,
  personalDetailsId?: number
): boolean => {
  let envKey = `${ENV_PREPEND}${feature}`;
  let envValue = env(envKey).toString();
  if (envValue === "1") {
    return true;
  }
  if (typeof envValue === "string") {
    let allowedIds: string[] = envValue.split(",");
    if (personalDetailsId) {
      let personalDetailsIdString: string = personalDetailsId + "";
      return (
        allowedIds.findIndex(
          (id: string) => id.trim() === personalDetailsIdString
        ) >= 0
      );
    }
  }
  return false;
};

export const feat = (feature: Feature, personalDetailsId?: number): boolean => {
  let result: boolean = featFlagFromEnv(feature, personalDetailsId);
  return result;
};
